<template>
  <component :is="menu === undefined ? 'b-card' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="menu === undefined">
      <h4 class="alert-heading">Error fetching menu data</h4>
      <div class="alert-body">
        No menu found with this menu id. Check
        <b-link class="alert-link" :to="{ name: 'apps-menus-list' }">
          Header List
        </b-link>
        for other menus.
      </div>
    </b-alert>
    <b-row>
      <b-col cols="12" class="p-3">
        <h4>{{$t('tooltips.preview')}}</h4>
        <preview-nav @editLink="editLinkHandler" :menu="menu"></preview-nav>
      </b-col>
      <b-col cols="12">
        <hr />
        <menu-form
          v-model="menu"
          :editLink="editLink"
          :images="images"
          @reload="fetchImages"
          @link_edited="editLink = null"
          @link_deleted="editLink = null"
          @edit_canceled="editLink = null"
        ></menu-form>
      </b-col>
    </b-row>
  </component>
</template>

<script>
import { BCard, BAlert, BCol, BRow, BFormInput, BLink } from "bootstrap-vue";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import menuStoreModule from "../menuStoreModule";
import MenuForm from "./menu-form";
import PreviewNav from "@/views/components/templates/menu/nav.vue";

export default {
  components: {
    BCard,
    BAlert,
    BCol,
    BRow,
    BFormInput,
    BLink,
    MenuForm,
    PreviewNav,
  },
  data() {
    return {
    };
  },
  watch: {
    "$store.state.whitelabelCurrencyNabvar.whitelabel": function () {
      this.storeImage();
    },
  },
  beforeMount() {
    this.storeImage();
  },
  setup() {
    const menu = ref({
      name: null,
      background_color: "#000000",
      background_image: null,
      color: "#ffffff",
      hover_color: "#f0f0f0",
      font_size: 12,
      title: "menu", //titulo para un menu en caso que sea desplegable
      is_desplegable: false,
      links: [],
    });
    const editLink = ref(null);
    const HEADER_APP_STORE_MODULE_NAME = "app-menu";
    if (!store.hasModule(HEADER_APP_STORE_MODULE_NAME))
      store.registerModule(HEADER_APP_STORE_MODULE_NAME, menuStoreModule);
    onUnmounted(() => {
      if (store.hasModule(HEADER_APP_STORE_MODULE_NAME))
        store.unregisterModule(HEADER_APP_STORE_MODULE_NAME);
    });
    if (router.currentRoute.params.id) {
      store
        .dispatch("app-menu/fetchMenu", { id: router.currentRoute.params.id })
        .then((response) => {
          menu.value = response.data;
          console.log(menu.value);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            menu.value = undefined;
          }
        });
    }
    const images = ref([]);

    const storeImage = async () => {
      await store
        .dispatch(
          "app-menu/fetchMenuIamge",
          store.state.whitelabelCurrencyNabvar.whitelabel.client
        )
        .then((response) => {
          images.value = response.data;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            images.value = [];
          }
        });
    };
    const editLinkHandler = (id) => {
      if (id.length == 1 || id[1] < 0) {
        editLink.value = { ...menu.value.links[id[0]], index: id };
      } else if (id.length > 1) {
        editLink.value = {
          ...menu.value.links[id[0]].children[id[1]],
          index: id,
        };
      }
    };
    return {
      menu,
      editLinkHandler,
      images,
      storeImage,
      editLink,
    };
  },
  methods: {
    async fetchImages() {
      await store
        .dispatch(
          "app-menu/fetchMenuIamge",
          store.state.whitelabelCurrencyNabvar.whitelabel.client
        )
        .then((response) => {
          images.value = response.data;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            images.value = [];
          }
        });
    },
  },
};
</script>

<style></style>
