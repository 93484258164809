import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMenus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/menus`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMenu(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/menus/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMenu(ctx, menuData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/menus`,  menuData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    updateMenu(ctx, { id, menuData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${APP_URL}/menus/${id}`,  menuData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMenuIamge(ctx,  id ) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/files/${id}?path=menu`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeMenu(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${APP_URL}/menus/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
