<template>
  <div>
    <!-- Form -->
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12" md="7" lg="8">
            <b-row class="form-general">
              <b-col cols="12">
                <h4>{{ $t('general_menu_data') }}</h4>
                <hr />
              </b-col>
              <div class="row border w-100 m-0 py-1">
                <b-col cols="12">
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-group label="name" label-for="name">
                      <template #label>{{ $t("labels.name") }}</template>
                      <b-form-input
                        id="name"
                        type="text"
                        v-model="menu.name"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider
                    #default="validationContext"
                    name="fontSize"
                    class="w-100"
                  >
                    <b-form-group label="With" label-for="fontSize">
                      <template #label>{{ $t("font_size") }}</template>
                      <b-form-input
                        id="fontSize"
                        type="number"
                        v-model="menu.font_size"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="4">
                  <validation-provider
                    #default="validationContext"
                    name="BackgroundColor"
                    rules="required"
                  >
                    <b-form-group
                      label="Background color"
                      label-for="backgroundcolor"
                    >
                      <template #label>{{
                        $t("labels.backgroundcolor")
                      }}</template>
                      <b-form-input
                        id="backgroundcolor"
                        type="color"
                        v-model="menu.background_color"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="4">
                  <validation-provider
                    #default="validationContext"
                    name="textColor"
                    rules="required"
                  >
                    <b-form-group label="text color" label-for="textColor">
                      <template #label>{{ $t("text_color") }}</template>
                      <b-form-input
                        id="textColor"
                        type="color"
                        v-model="menu.color"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="4">
                  <validation-provider
                    #default="validationContext"
                    name="hoverColor"
                    rules="required"
                  >
                    <b-form-group label="text color" label-for="hoverColor">
                      <template #label>{{ $t("labels.hovercolor") }}</template>
                      <b-form-input
                        id="hoverColor"
                        type="color"
                        v-model="menu.hover_color"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" class="img">
                  <image-field-form
                    :preview="true"
                    id="backgroundImage"
                    :text="$t('labels.backgroundimage')"
                    v-model="menu.background_image"
                    @changeImage="setOrRealoadImages"
                    path="menu"
                    :images="images"
                  >
                  </image-field-form>
                </b-col>
              </div>
            </b-row>
          </b-col>
          <b-col cols="12" md="5" lg="4">
            <b-row class="form-links">
              <b-col cols="12">
                <h4> {{ $t('new_button_data') }} </h4>
                <hr />
              </b-col>
              <div class="border py-1 ml-md-1 w-100">
                <b-col v-if="editLink" cols="12">
                  <b-button
                    @click="deleteLink"
                    variant="danger"
                    class="w-100 py-1"
                    >{{ `${$t('buttons.delete')}` + correctLink.buttonType }}</b-button
                  >
                </b-col>
                <b-col v-if="!editLink" class="border-md-left" cols="12">
                  <validation-provider #default="validationContext" name="type">
                    <b-form-group label-for="type">
                      <template #label>{{ $t('button_type') }}</template>
                      <v-select
                        id="title"
                        v-model="link.buttonType"
                        label="text"
                        :options="['link', 'submenu', 'product', 'lobby']"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <template
                  v-if="
                    correctLink.buttonType === 'link' ||
                    (correctLink.buttonType === 'product' && !!editLink) ||
                    (correctLink.buttonType === 'lobby' && !!editLink)
                  "
                >
                  <b-col class="border-md-left" cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="title"
                    >
                      <b-form-group label-for="title">
                        <template #label>{{ $t("title") }}</template>
                        <b-form-input
                          id="title"
                          type="text"
                          v-model="correctLink.title"
                          :state="getValidationState(validationContext)"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="border-md-left" cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="url"
                    >
                      <b-form-group label-for="url">
                        <template #label>url</template>
                        <b-form-input
                          :disabled="
                            correctLink.buttonType === 'product' ||
                            correctLink.buttonType === 'lobby'
                          "
                          id="url"
                          type="text"
                          v-model="correctLink.href"
                          :state="getValidationState(validationContext)"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="icon"
                    >
                      <b-form-group label-for="icon">
                        <template #label>icon</template>
                        <b-form-input
                          id="icon"
                          type="text"
                          v-model="correctLink.icon"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="position"
                    >
                      <b-form-group label-for="position">
                        <template #label>position</template>
                        <b-form-input
                          id="position"
                          type="number"
                          min="0"
                          v-model="correctLink.position"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </template>
                <template v-if="correctLink.buttonType === 'submenu'">
                  <b-col cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="title"
                    >
                      <b-form-group label-for="title">
                        <template #label>{{ $t("title") }}</template>
                        <b-form-input
                          id="title"
                          type="text"
                          v-model="correctLink.title"
                          :state="getValidationState(validationContext)"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="icon"
                    >
                      <b-form-group label-for="icon">
                        <template #label>{{ $t("icon") }}</template>
                        <b-form-input
                          id="icon"
                          type="text"
                          v-model="correctLink.icon"
                          :state="getValidationState(validationContext)"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="position"
                    >
                      <b-form-group label-for="position">
                        <template #label>{{ $t("position") }}</template>
                        <b-form-input
                          id="position"
                          type="number"
                          min="0"
                          v-model="correctLink.position"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </template>
                <template
                  v-if="correctLink.buttonType === 'product' && !editLink"
                >
                  <b-col cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="Products"
                      rules="required"
                    >
                      <b-form-group label-for="products">
                        <template #label>{{ $t("labels.products") }} </template>
                        <v-select
                          v-model="productToLink"
                          label="name"
                          :options="products"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="position"
                    >
                      <b-form-group label-for="position">
                        <template #label>position</template>
                        <b-form-input
                          id="position"
                          type="number"
                          min="0"
                          v-model="productToLink.position"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </template>
                <template
                  v-if="correctLink.buttonType === 'lobby' && !editLink"
                >
                  <b-col cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="lobby"
                      rules="required"
                    >
                      <b-form-group label-for="lobby">
                        <template #label>{{ $t("lobby") }} </template>
                        <v-select
                          v-model="lobbyToLink"
                          label="name"
                          track-by="name"
                          :options="lobbies"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="validationContext"
                      name="position"
                    >
                      <b-form-group label-for="position">
                        <template #label>position</template>
                        <b-form-input
                          id="position"
                          type="number"
                          min="0"
                          v-model="lobbyToLink.position"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </template>
                <b-col
                  v-if="correctLink.buttonType"
                  class="border-md-left"
                  cols="12"
                >
                  <b-button
                    @click="linkButtonHandler"
                    variant="primary"
                    class="mr-1"
                    >{{
                      editLink
                        ? $t('tabs.edit') + correctLink.buttonType
                        : $t('tabs.add') + correctLink.buttonType
                    }}</b-button
                  >
                  <b-button
                    v-if="editLink"
                    variant="secondary"
                    @click="$emit('edit_canceled')"
                    >
                    {{ $t('buttons.cancel') }}
                  </b-button>
                </b-col>
              </div>
              <div
                v-if="correctLink.buttonType === 'submenu' && editLink"
                class="border w-100 ml-md-1 mt-1 py-1"
              >
                <b-col class="border-md-left" cols="12">
                  <validation-provider
                    #default="validationContext"
                    name="title"
                  >
                    <b-form-group label-for="title">
                      <template #label>{{ $t("title") }}</template>
                      <b-form-input
                        id="title"
                        type="text"
                        v-model="subLink.title"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col class="border-md-left" cols="12">
                  <validation-provider #default="validationContext" name="url">
                    <b-form-group label-for="url">
                      <template #label>url</template>
                      <b-form-input
                        id="url"
                        type="text"
                        v-model="subLink.href"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider #default="validationContext" name="icon">
                    <b-form-group label-for="icon">
                      <template #label>icon</template>
                      <b-form-input
                        id="icon"
                        type="text"
                        v-model="subLink.icon"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider
                    #default="validationContext"
                    name="position"
                  >
                    <b-form-group label-for="position">
                      <template #label>{{ `${t('position')}` }}</template>
                      <b-form-input
                        id="position"
                        type="number"
                        min="0"
                        v-model="subLink.position"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col class="border-md-left" cols="12">
                  <b-button
                    @click="addSubLink(editLink.index[0])"
                    variant="primary"
                    class="mr-1"
                  >
                    {{ $t('add_link') }}
                  </b-button>
                </b-col>
              </div>
            </b-row>
          </b-col>
        </b-row>
        <hr />
        <div class="text-center mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            v-if="!menu._id"
          >
            {{ $t("buttons.add") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            v-else
          >
            {{ $t("buttons.update") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="$router.resolve({ name: 'apps-menus-list' }).href"
          >
            {{ $t("buttons.cancel") }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSelect,
  BRow,
  BCol,
  BCard,
  BCardText,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {getCurrentInstance, ref} from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import ImageFieldForm from "@/views/components/ImageFieldForm";
import vSelect from "vue-select";
import axios from "@axios";
import {translatableText} from "@core/utils/utils";
import {axiosErrorHandle} from "@core/utils/errorHandler";
const BACK_URL = process.env.VUE_APP_URL;

export default {
  components: {
    vSelect,
    BFormCheckbox,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    ImageFieldForm,
  },
  props: ["menu", "images", "editLink"],
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      options: [
        { text: this.$t("left"), value: 1 },
        { text: this.$t("center"), value: 2 },
        { text: this.$t("right"), value: 3 },
      ],
      link: { position: 0 },
      products: [],
      lobbies: [],
      backURL: BACK_URL,
      lobbySelected: {},
      productSelected: {},
      subLink: { buttonType: "link", position: 0 },
      editingSubLink: false,
    };
  },
  model: {
    prop: "menu",
    event: "menu-changed",
  },
  created() {
    this.getProducts();
    this.getLobbies();
  },
  computed: {
    productToLink: {
      get() {
        return this.productSelected;
      },
      set(value) {
        console.log(value);
        //si el valor es positicon
        if (value.hasOwnProperty("position")) {
          this.link.position = value.position;
        } else {
          this.productSelected = value;
          this.link.id = value.productId;
          this.link.title = value.name;
          this.link.href = `/product/${this.link.id}`;
        }
      },
    },
    lobbyToLink: {
      get() {
        return this.lobbySelected;
      },
      set(value) {
        console.log(value);
        this.lobbySelected = value;
        //si el valor es positicon
        if (value.hasOwnProperty("position")) {
          this.link.position = value.position;
        } else {
          this.link.id = value._id;
          this.link.title = value.name;
          this.link.href = `/lobby?id=${value._id}`;
          this.link.position = this.productToLink.position || 0
        }
      },
    },
    correctLink: {
      get() {
        if (!this.editLink) return this.link;

        let index = this.editLink.index;

        if (index.length == 1 || index[1] < 0) {
          return this.menu.links[index[0]];
        } else if (index.length > 1) {
          return this.menu.links[index[0]].children[index[1]];
        }
      },
      set(newval) {
        if (this.editLink) this.updateLink(newval);
        else this.link = newval;
      },
    },
  },
  methods: {
    async getProducts() {
      //products get
      try {
        const clientId = store.state.whitelabelCurrencyNabvar.whitelabel.client
        const response = await axios.get(`${this.backURL}/clients/availProd/${clientId}`);
        const objects = response.data
        objects.forEach(element => {
            this.products.push({ productId: element.product.productId, name: element.product.name })
        })
        // this.products = data.filter((pro) => pro.isgame);
      } catch (err) {
        console.error(err);
      }
    },
    async getLobbies() {
      //lobbies get
      const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
      try {
        const response = await axios.get(this.backURL + "/lobbies",  {
          params: {
            whitelabelId
          }
        });
        const data = response.data.lobbys;
        this.lobbies = data;
      } catch (err) {
        console.error(err);
      }
    },
    linkButtonHandler() {
      if (this.editLink) {
        this.$emit("link_edited");
      } else {
        this.addLink();
      }
    },
    handleSubMenuAction(index) {
      //si lo que se esta edtiando es un submenu
      //verifixar que no se este editando un hijo
      if (index[1] < 0) {
        this.addSubLink(index[0]);
      } else {
        this.updateSubLink(this.subLink);
      }
    },
    addSubLink(index) {
      console.log("indice", index);
      if (this.menu.links[index].children) {
        this.menu.links[index].children.push(this.subLink);
      } else {
        this.menu.links[index].children = [this.subLink];
      }
      this.subLink = { buttonType: "link" };
      this.$emit("menu-changed", this.menu);
    },
    addLink() {
      this.menu.links.push(this.link);
      this.link = {};
      this.$emit("menu-changed", this.menu);
    },
    deleteLink() {
      let index = this.editLink.index;
      if (index.length == 1 || index[1] < 0) {
        this.menu.links.splice([index[0]], 1);
      } else if (index.length > 1) {
        this.menu.links[index[0]].children.splice([index[1]], 1);
      }
      this.productSelected = {};
      this.lobbySelected = {};

      this.$emit("menu-changed", this.menu);
      this.$emit("link_deleted");
    },
    updateLink(val) {
      let index = this.editLink.index[0];
      this.menu.links[index] = val;
      this.$emit("menu-changed", this.menu);
    },
    updateSubLink(val) {
      let index = this.editLink.index;
      this.menu.links[index[0]].children[index[1]] = val;
      this.editingSubLink = false;
      this.$emit("menu-changed", this.menu);
    },
    setOrRealoadImages(value) {
      if (value === "reload") return this.$emit('reload');
      this.menu.background_image = value;
    },
  },

  setup(props) {
    const toast = useToast();
    const vm = getCurrentInstance()
      
    const onSubmit = async () => {
      // props.menu.links=props.links
      try {
        if (props.menu._id) {
          await store.dispatch("app-menu/updateMenu", {
            id: props.menu._id,
            menuData: props.menu,
          });
          toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t('menu_updated'),
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          await store.dispatch("app-menu/addMenu", props.menu);
          toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t('menu_created'),
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "error creating or update menu",
            text: await translatableText({text: axiosErrorHandle(error)}),
            icon: "EditIcon",
            variant: "error",
          },
        });
      }
    };

    const data = ref(JSON.parse(JSON.stringify(props.menu)));
    const resetdata = () => {
      data.value = JSON.parse(JSON.stringify(props.menu));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetdata);

    return {
      data,
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
    };
  },
};
</script>

<style scoped>
.border {
  border-radius: 5px;
}
.form-links,
.form-general {
  padding-top: 1rem;
}
</style>
