<template>
  <nav class="mx-0 px-0" :style="Style">
    <div id="menu_container">
      <div class="list-reset links-container">
        <template v-if="menu" class="transition-all duration-500">
          <template v-for="(link, i) in menu.links">
            <div :class="`link-cont order-${link.position}`" v-if="link.buttonType === 'submenu'"
              @mouseover="dropItems = true" @mouseleave="dropItems = false">
              <p :style="StyleLink" class="link" @click="handleSubmenu(i)">{{ link.title }}</p>
              <ul :class="`ul-drop order-${link.position}`" v-if="dropItems && link.buttonType == 'submenu'">
                <template class="transition-all duration-500">

                  <li :style="StyleLink" v-for="(item, j) in link.children" :key="j"
                    :class="`link ul-drop-item order-${item.position}`" @click="$emit('editLink', [i, j])">
                    {{ item.title }}
                  </li>
                </template>
              </ul>
            </div>

            <p v-else :key="i" :class="`link order-${link.position}`" @click="$emit('editLink', [i])" :style="StyleLink">
              {{ link.title }}
            </p>
          </template>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
// import SocialNav from '@/components/layouts/header/social-nav.vue';
// import LinkDropdown from './link-dropdown.vue'
import { BBadge, BDropdown, BDropdownItem, BIcon, BIconArrowUp, BIconArrowDown } from 'bootstrap-vue'

export default {
  props: ['menu'],
  name: 'preview-nav',
  data() {
    return {
      dropItems: false
    }
  },
  components: {
    // SocialNav,
    BBadge,
    BDropdown, BDropdownItem,
    BIcon, BIconArrowUp, BIconArrowDown
  },
  mounted() {
    if (this.$refs.dd != undefined) {
      this.$refs.dd[0].$el.classList.add('link')
    }
  },
  methods: {
    handleSubmenu(i) {
      this.dropItems = !this.dropItems
      this.$emit('editLink', [i, -1])
    }
  },
  computed:
  {
    Style() {
      return {
        "background-color": this.menu.background_color ? this.menu.background_color : '',
        "background-image": this.menu.background_image ? `url('${this.menu.background_image}')` : '',
        "background-size": "100% !important",
        "background-repeat": "no-repeat !important",
      }
    },
    totalMenuSize() {
      let size = 0
      this.menu.links.forEach((link) => {
        size += (link.title.length / 2) + 3
      })
      return size
    },
    StyleLink() {
      return {
        "--background-color-hover": this.menu.hover_color ? this.menu.hover_color : '',
        "--background-color": this.menu.background_color ? this.menu.background_color : 'black',
        "font-size": this.menu.font_size ? `${this.menu.font_size}px` : '12px',
        "height": "54px",
        "color": this.menu.color ? this.menu.color : 'white',
        "text-align": "center"
      }
    }
  },
}
</script>

<style>
.items-center {
  align-items: center;
}

#menu_container {
  text-align: center;
  padding: 0;
  margin: 0;
}

.link-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: auto;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.link:hover {
  transition: .5s;
  opacity: 70%;
  background-color: var(--background-color-hover);

}

.links-container {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0;
}

.ba {
  z-index: 99;
}

.ul-drop {
  position: absolute;
  z-index: 1;
  margin-top: 1;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 0;
  top: 8.9rem;
}

.ul-drop,
.ul-drop-item {
  background-color: var(--background-color);
  list-style: none;

}

.ul-drop-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;

}

@media screen and (max-width: calc(calc(1rem + 3rem))) {}
</style>